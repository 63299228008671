import React, { useRef } from 'react'
import styles from './styles.module.scss'
import Container from '@/Components/Shared/Container'
import PrimaryButton from '@/Components/Shared/Buttons/Primary'
import SecondaryButton from '@/Components/Shared/Buttons/Secondary'
import { gsap, ScrollTrigger } from 'gsap/all'
import { useGSAP } from '@gsap/react'
import { Link } from 'react-router-dom'

gsap.registerPlugin(ScrollTrigger)

let mm = gsap.matchMedia()

const AuthorHome = () => {
  const wrapperRef = useRef()
  const headerRef = useRef()
  const descRef = useRef()
  const buttonsRef = useRef()
  const leftImageRef = useRef()
  const rightImageRef = useRef()
  const rightImageOneRef = useRef()
  const rightImageTwoRef = useRef()

  useGSAP(() => {
    mm.add('(min-width: 800px)', () => {
      let t1 = gsap.timeline({
        scrollTrigger: {
          trigger: wrapperRef.current,
          markers: false,
          pin: true, // pin the trigger element while active
          start: 'top +=0', // when the top of the trigger hits the top of the viewport
          end: '+=1000 0%', // end after scrolling 500px beyond the start
          scrub: true,
          pinSpacing: true, // Adjust pin spacing
        },
      })

      t1.fromTo(
        headerRef.current,
        {
          y: 20,
          x: 40,
          opacity: 0,
          ease: 'power1.easeInOut',
        },
        {
          y: 0,
          x: 0,
          opacity: 1,
          ease: 'power1.easeInOut',
        },
      )
        .fromTo(
          descRef.current,
          {
            scale: 0.8,
            opacity: 0,
            ease: 'power1.easeInOut',
          },
          {
            scale: 1,
            opacity: 1,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          buttonsRef.current,
          {
            scale: 0.8,
            opacity: 0,
            ease: 'power1.easeInOut',
          },
          {
            scale: 1,
            opacity: 1,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          leftImageRef.current,
          {
            x: -100,
            y: 500,
            ease: 'power1.easeInOut',
          },
          {
            x: 0,
            y: 0,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          rightImageRef.current,
          {
            x: -100,
            y: -500,
            ease: 'power1.easeInOut',
          },
          {
            x: 0,
            y: 0,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          rightImageOneRef.current,
          {
            x: 200,
            y: 500,
            ease: 'power1.easeInOut',
          },
          {
            x: 0,
            y: 0,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          rightImageTwoRef.current,
          {
            x: 200,
            y: -600,
            ease: 'power1.easeInOut',
          },
          {
            x: 0,
            y: 0,
            ease: 'power1.easeInOut',
          },
          '<',
        )
      // .fromTo(
      //   wrapperRef.current,
      //   {
      //     y: 0,
      //     opacity: 1,
      //     scale: 1,
      //     ease: 'power1.easeInOut',
      //   },
      //   {
      //     y: 60,
      //     opacity: 0,
      //     scale: 0.8,
      //     ease: 'power1.easeInOut',
      //   },
      // )
    })
  })

  return (
    <div className={styles.mainCont}>
      <div className={styles.wrapper} ref={wrapperRef}>
        <Container>
          <h4 ref={headerRef}>Ready to become an author?</h4>
          <p ref={descRef}>
            Our ghostwriters provide the expertise and dedication to bring your
            ideas to life, authentically and powerfully, ensuring your book
            leaves a lasting impact
          </p>
          <div className={styles.ctaContainer} ref={buttonsRef}>
            <Link to={'/contact-us'}>
              <PrimaryButton>Book a meeting</PrimaryButton>
            </Link>
            <SecondaryButton>Check samples</SecondaryButton>
          </div>
          <div className={styles.countWrapper}>
            <div className={styles.countLabel}>
              Don’t believe us? We’ve got the numbers to prove it.
            </div>
            <div className={styles.countList}>
              <div className={styles.countContainer}>
                <div className={styles.number}>4322</div>
                <div className={styles.caption}>Non-fictional books</div>
              </div>
              <div className={styles.countContainer}>
                <div className={styles.number}>0491</div>
                <div className={styles.caption}>Published authors</div>
              </div>

              <div className={styles.countContainer}>
                <div className={styles.number}>8972</div>
                <div className={styles.caption}>Fictional Books</div>
              </div>

              <div className={styles.countContainer}>
                <div className={styles.number}>3867</div>
                <div className={styles.caption}>E-books</div>
              </div>
            </div>
          </div>
        </Container>
        <div className={styles.bookList}>
          <img
            src="/images/b_the_wim_hof.png"
            alt=""
            className={styles.book1}
            ref={rightImageRef}
          />
          <img
            src="/images/b_tana_french_in_the_woods.png"
            alt=""
            className={styles.book2}
            ref={rightImageOneRef}
          />
          <img
            src="/images/b_start_with_why.png"
            alt=""
            className={styles.book3}
            ref={rightImageTwoRef}
          />
          <img
            src="/images/hero_book_left_4.png"
            alt=""
            className={styles.book4}
            ref={leftImageRef}
          />
        </div>
      </div>
    </div>
  )
}
export default AuthorHome
