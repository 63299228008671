import React, { useRef } from 'react'
import styles from './styles.module.scss'
import Container from '@/Components/Shared/Container'
import SecondaryButton from '@/Components/Shared/Buttons/Secondary'
import PrimaryButton from '@/Components/Shared/Buttons/Primary'
import { gsap, ScrollTrigger } from 'gsap/all'
import { useGSAP } from '@gsap/react'
import { Link } from 'react-router-dom'

gsap.registerPlugin(ScrollTrigger)

let mm = gsap.matchMedia()

const HomeHeroSection = () => {
  const wrapperRef = useRef()
  const headerTextOne = useRef()
  const headerTextTwo = useRef()
  const descSection = useRef()
  const leftTop = useRef()
  const leftBottom = useRef()
  const leftMidOne = useRef()
  const leftMidTwo = useRef()
  const rightTop = useRef()
  const rightBottom = useRef()
  const rightMidOne = useRef()
  const rightMidTwo = useRef()
  const bottomBanner = useRef()

  const wrapperOneRef = useRef()
  const contRef = useRef()
  const textRef = useRef()
  const listRef = useRef()
  const leftItemRef = useRef()
  const midItemRef = useRef()
  const rightItemRef = useRef()

  useGSAP(() => {
    mm.add('(min-width: 800px)', () => {
      let t1 = gsap.timeline({
        scrollTrigger: {
          trigger: wrapperRef.current,
          markers: false,
          pin: true, // pin the trigger element while active
          start: 'top +=0', // when the top of the trigger hits the top of the viewport
          end: '+=2000 0%', // end after scrolling 500px beyond the start
          scrub: true,
          pinSpacing: true, // Adjust pin spacing
        },
      })

      t1.fromTo(
        headerTextOne.current,
        {
          y: 0,
          scale: 1,
          ease: 'power1.easeInOut',
        },
        {
          y: -15,
          scale: 0.6,
          ease: 'power1.easeInOut',
        },
      )
        .fromTo(
          headerTextTwo.current,
          {
            y: 0,
            scale: 1,
            ease: 'power1.easeInOut',
          },
          {
            y: 5,
            scale: 1.2,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          descSection.current,
          {
            y: 0,
            scale: 1,
            ease: 'power1.easeInOut',
          },
          {
            y: 10,
            scale: 0.8,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          bottomBanner.current,
          {
            y: 0,
            opacity: 1,
            ease: 'power1.easeInOut',
          },
          {
            y: 200,
            opacity: 0,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          leftBottom.current,
          {
            x: 0,
            ease: 'power1.easeInOut',
          },
          {
            x: 500,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          leftMidOne.current,
          {
            y: 0,
            ease: 'power1.easeInOut',
          },
          {
            y: 290,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          leftMidTwo.current,
          {
            x: 0,
            ease: 'power1.easeInOut',
          },
          {
            x: 300,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          rightTop.current,
          {
            y: 0,
            ease: 'power1.easeInOut',
          },
          {
            y: 100,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          rightBottom.current,
          {
            x: 0,
            y: 0,
            ease: 'power1.easeInOut',
          },
          {
            x: -500,
            y: 160,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          rightMidOne.current,
          {
            y: 0,
            ease: 'power1.easeInOut',
          },
          {
            y: 400,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          rightMidTwo.current,
          {
            x: 0,
            ease: 'power1.easeInOut',
          },
          {
            x: -300,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          headerTextOne.current,
          {
            opacity: 1,
            ease: 'power1.easeInOut',
          },
          {
            opacity: 0,
            ease: 'power1.easeInOut',
          },
        )
        .fromTo(
          descSection.current,
          {
            opacity: 1,
            ease: 'power1.easeInOut',
          },
          {
            opacity: 0,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          leftTop.current,
          {
            x: 0,
            ease: 'power1.easeInOut',
          },
          {
            x: -200,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          leftBottom.current,
          {
            x: 500,
            opacity: 1,
            ease: 'power1.easeInOut',
          },
          {
            x: 700,
            opacity: 0,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          leftMidOne.current,
          {
            x: 0,
            ease: 'power1.easeInOut',
          },
          {
            x: -300,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          leftMidTwo.current,
          {
            x: 300,
            ease: 'power1.easeInOut',
          },
          {
            x: -300,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          rightTop.current,
          {
            x: 0,
            y: 100,
            opacity: 1,
            ease: 'power1.easeInOut',
          },
          {
            x: -470,
            y: 535,
            opacity: 0,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          rightBottom.current,
          {
            x: -500,
            // y: 160,
            opacity: 1,
            ease: 'power1.easeInOut',
          },
          {
            x: -800,
            // y: -160,
            opacity: 0,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          rightMidOne.current,
          {
            x: 0,
            ease: 'power1.easeInOut',
          },
          {
            x: 500,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          rightMidTwo.current,
          {
            x: -300,
            ease: 'power1.easeInOut',
          },
          {
            x: 300,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          headerTextTwo.current,
          {
            y: 5,
            ease: 'power1.easeInOut',
          },
          {
            y: -100,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          listRef.current,
          {
            y: 600,
            ease: 'power1.easeInOut',
          },
          {
            y: -550,
            ease: 'power1.easeInOut',
          },
          '<',
        )
      // .fromTo(leftItemRef.current, {
      //   y: -1500,
      //   x: -200,
      //   ease: "power1.easeInOut"
      // }, {
      //   x: 0,
      //   y: 0,
      //   ease: "power1.easeInOut"
      // }, '<')

      // .fromTo(midItemRef.current, {

      //   y: 550,
      //   x: 300,
      //   ease: "power1.easeInOut"
      // }, {

      //   x: 0,
      //   y: 0,
      //   ease: "power1.easeInOut"
      // }, '<').fromTo(rightItemRef.current, {

      //   y: -500,
      //   x: 700,
      //   ease: "power1.easeInOut"
      // }, {

      //   x: 0,
      //   y: 0,
      //   ease: "power1.easeInOut"
      // }, '<')
    })
  })

  return (
    <>
      <div className={styles.wrapper} ref={wrapperRef}>
        <Container>
          <h1 ref={headerTextOne}>Your voice deserves applause.</h1>

          <h1 ref={headerTextTwo}>We write the standing ovation.</h1>
          <div ref={descSection}>
            <p>
              Life is too short to keep your story from the world. Turn your
              dream book into reality with our expert ghostwriting services
            </p>

            <div className={styles.catContainer}>
              <Link to={'/contact-us'}>
                <PrimaryButton>Get your book</PrimaryButton>
              </Link>
              <SecondaryButton>Get Expert Help</SecondaryButton>
            </div>
            <div className={styles.scroll}>Scroll Down</div>
            <img
              src="/images/scroll-down-arrow.svg"
              alt="scroll"
              className={styles.scrollImage}
            />
          </div>
        </Container>
        <div className={styles.booksCollection}>
          <img
            src="/images/hero_book_left_1.png"
            alt=""
            className={styles.book1}
            ref={leftTop}
          />

          <div className={styles.bookLeftOne} ref={leftMidTwo}>
            <img src="/images/hero_book_left_2.png" alt="" />
            <img src="/images/hero_book_left_3.png" alt="" />
          </div>
          <div className={styles.bookLeftTwo} ref={leftBottom}>
            <img src="/images/hero_book_left_5.png" alt="" />
            <img
              src="/images/hero_book_left_6.png"
              alt=""
              className={styles.topPadding}
            />
          </div>
          <img
            src="/images/hero_book_left_4.png"
            ref={leftMidOne}
            alt=""
            className={styles.book2}
          />

          <img
            src="/images/hero_book_right_1.png"
            alt=""
            className={styles.book4}
            ref={rightTop}
          />
          <div className={styles.bookRightOne} ref={rightMidTwo}>
            <img src="/images/hero_book_left_5.png" alt="" />
            <img src="/images/hero_book_right_2.png" alt="" />
          </div>
          <div className={styles.bookRightTwo} ref={rightBottom}>
            <img src="/images/hero_book_right_5.png" alt="" />
            <img
              src="/images/hero_book_right_4.png"
              alt=""
              className={styles.topRightPadding}
            />
          </div>
          <img
            src="/images/hero_book_right_3.png"
            alt=""
            className={styles.book5}
            ref={rightMidOne}
          />
        </div>

        <img
          src="/images/hero_bottom.png"
          alt=""
          className={styles.heroBottom}
          ref={bottomBanner}
        />
      </div>
      <section
        className={styles.wrapperOne}
        ref={wrapperOneRef}
        id={'services'}
      >
        <Container>
          <div ref={contRef}>
            <h2 ref={textRef}>Write the book you want!</h2>
            <div className={styles.list} ref={listRef}>
              <Link
                to={'/service/fictional'}
                className={`${styles.card} ${styles.one}`}
              >
                <h3>Fictional</h3>
                <img
                  src="/images/hero_book_left_5.png"
                  alt=""
                  ref={leftItemRef}
                />
              </Link>
              <Link
                to={'/service/non-fictional'}
                className={`${styles.card} ${styles.two}`}
              >
                <h3>Non-Fictional</h3>
                <img
                  src="/images/hero_book_right_5.png"
                  alt=""
                  ref={midItemRef}
                />
              </Link>
              <Link
                to={'/service/ebook'}
                className={`${styles.card} ${styles.three}`}
              >
                <h3>E-books</h3>
                <img
                  src="/images/hero_book_right_1.png"
                  alt=""
                  ref={rightItemRef}
                />
              </Link>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}
export default HomeHeroSection
