import styles from '@/Styles/content.module.scss'
import Container from '@/Components/Shared/Container'
import Header from '@/Components/Header'
import Footer from '@/Components/Footer'
import { Link } from 'react-router-dom'
const ServicePolicies = () => {
  return (
    <>
      <Header />

      <div className={styles.wrapper}>
        <div className={styles.hero}>
          <Container>
            <div className={styles.breadcrumb}>
              <Link to="/">Home</Link>{' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M0.833008 11.4447L6.16634 6.11141L0.833008 0.778076"
                  stroke="#606060"
                  stroke-width="1.06667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span>Service Policies</span>
            </div>
            <h1>Service Policies</h1>
          </Container>
        </div>
        <Container>
          <div className={styles.content}>
            To know more about Estorytellers’s services, carefully read the
            policies mentioned on this page.
            <br/>
            <br/>
            <h3>USE OF POLICIES</h3>
            <p>
              The policies cited below govern the use of this website.
              Estorytellers reserves the right to modify, update or delete
              policies and any of its provisions at its consideration and
              without prior notice. You are advised to keep yourself informed
              about any such updates.
            </p>
            <br/>
            <h3>REVISION POLICY</h3>
            <p>
              Estorytellers ultimately aims to satisfy each and every customer,
              and hence a revision policy has been carefully crafted. The below
              given are the conditions of the revision policy, subject to change
              at any moment.
            </p>
            <p>
              If the work delivered doesn’t meet the expectations of the
              customer, they have the right to request a revision. We provide
              unlimited revisions free of cost. However, a few conditions will
              be discussed in detail below.
            </p>
            <br/>
            <p>
              There are two ways for a customer to cite a revision in the work
              they received.
            </p>
            <br/>
            <ol>
              <li>
                The first method does not entail the marking of a review. The
                customer can revert to Estorytellers with the document they
                reserved and explain the revision via email. The writer in
                charge will make the necessary revisions and respond with the
                revised document.
              </li>
              <li>
                In the second method, the revision can be marked on the document
                itself. The customer can comment on the areas that need review.
              </li>
            </ol>
            <br/>
            <p>
              To maintain customer satisfaction, the first revision of the
              document will be returned to the client within 24 hours. If a
              second revision is made, the document will be returned in 48
              hours. Three or more revisions, however, require 72 hours. These
              timeframes are subject to change as per the content to be revised
              and the nature of the revision. If the revision does not keep with
              the initial guidelines provided by the client, the client may be
              expected to pay an adjustment fee. In any case, the complete
              payment has to be made before an order can be processed.
            </p>
            <br/>
            <p>
              To save both your time and ours, we request you to provide us with
              clear and straightforward guidelines while placing the order. This
              will prevent any miscommunication or misunderstanding among the
              two parties.
            </p>
            <br/>
            <h3>ORDER CANCELLATION AND REFUND POLICY</h3>
            <p>
              If for any reason, you request the cancellation of any order, the
              refund policy is contingent on the below conditions.
            </p>
            <ol>
              <li>
                When your order has been assigned to a writer, and you request
                an order cancellation, you are eligible for a 50% refund.
              </li>
              <li>
                When your order has not been assigned to a writer, and you
                request an order cancellation, you are eligible for a 90%
                refund.
              </li>
              <li>
                When details of the order are missing, and you request an order
                cancellation, you are eligible for a 95% refund.
              </li>
            </ol>
            <br/>
            <h3>DELIVERY POLICY</h3>
            <p>
              Our team is dedicated to delivering orders promptly. We are
              committed to providing the content without any delay, save for in
              the case of a technical discrepancy.
            </p>
            <br/>
            <p>
              Once the writer has finished the work, we review and send it to
              the client. The client will be able to download the content.
            </p>
            <br/>
            <p>
              The files are permanently deleted after a year in our systems.
              Clients are requested to keep a copy of the content for future
              use, as we won’t be able to retrieve the same content if requested
              for a year later.
            </p>
            <br/>
            <p>
              Timely delivery of the order is of the utmost priority to us.
              However, clients are requested to check the spam page. At the time
              the client will think the delivery is late, while the order has
              been sent in for review on time but was marked as spam.
            </p>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  )
}
export default ServicePolicies
