import Header from '@/Components/Header'
import HomeHeroSection from '@/Components/Home/HeroSection'
import BookTypes from '@/Components/Home/BookTypes'
import MakeDifference from '@/Components/Home/MakeDifference'
import AuthorHome from '@/Components/Home/Author'
import ClientsHome from '@/Components/Home/Clients'
import ProcessHome from '@/Components/Home/Process'
import Packages from '@/Components/Home/Packages'
import RecentBlogs from '@/Components/Home/RecentBlogs'
import Newsletter from '@/Components/Home/Newsletter'
import Footer from '@/Components/Footer'

const ClientData = [
    {
        text: '“I came across them while searching for SOP writing services. I approached them, and they delivered the SOP in no time. Overall, it was a good experience!”',
        color: '#FFF',
    },
    {
        text: '“Estorytellers are genuinely good at storytelling. We have an ongoing ghostwriting project, and it’s all been going smoothly until now. Keep it up!”',
        color: '#E6EAFF',
    },
    {
        text: '“Estorytellers have a well-trained team of writers, I believe. Thank you for my well-written resume. It helped me get the job I wanted. So, thanks!”',
        color: '#DAE0FF',
    },
    {
        text: '“I would recommend Estorytellers because of its transparency and customer service. They explained everything in the first meeting. So far, so good!”',
        color: '#CED6FF',
    },
]
const HomePage = () => {
  return (
    <>
      <Header />
      <HomeHeroSection />
      {/* <BookTypes /> */}
      <MakeDifference />
      <AuthorHome />
      <ClientsHome viewPointHeight Data={ClientData} cardColor={'#fff'}/>
      <ProcessHome />
      <Packages />
      <RecentBlogs />
      <Newsletter />
      <Footer />
    </>
  )
}
export default HomePage
