import styles from './styles.module.scss'
const ServiceOffer = ({ data, color, title }) => {
  return (
    <div className={styles.wrapper} style={{ background: color }}>
      <h2>{title ? title : `Any genre you want, we offer.`}</h2>
      <ul>
        {data.map((item, key) => {
          return (
            <li key={key}>
              <img src={item.imgSrc} alt="" />
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
export default ServiceOffer
