import React, { useRef } from 'react'
import styles from './styles.module.scss'
import Container from '@/Components/Shared/Container'
import PageButton from '@/Components/Service/PageButton'
import PrimaryButton from '@/Components/Shared/Buttons/Primary'
import SecondaryButton from '@/Components/Shared/Buttons/Secondary'
import { gsap } from 'gsap/all'
import { useGSAP } from '@gsap/react'

const FictionalHero = ({
  active,
  darkColor,
  lightColor,
  heading,
  subheading,
}) => {
  const buttonsRef = useRef()
  const imgOneRef = useRef()

  useGSAP(() => {
    const a1 = gsap.fromTo(
      buttonsRef.current,
      {
        opacity: 0,
        y: -100,
        scale: 0.8,
        ease: 'power1.easeInOut',
      },
      {
        opacity: 1,
        y: 0,
        scale: 1,
        duration: 0.7,
        ease: 'power1.easeInOut',
      },
    )

    const a2 = gsap.fromTo(
      imgOneRef.current,
      {
        y: 200,
        scale: 0.8,
        ease: 'power1.easeInOut',
      },
      {
        y: 0,
        scale: 1,
        duration: 0.7,
        ease: 'power1.easeInOut',
      },
    )

    // return () => {
    //   a1.reverse();
    //   a2.reverse();
    // }
  })

  return (
    <div
      className={styles.wrapper}
      style={
        active === 2
          ? {
              backgroundImage:
                'linear-gradient(to top, #FFEEEF 300px, white 10%)',
            }
          : {}
      }
    >
      <Container>
        <PageButton active={active} color={darkColor} />
        <h1>{heading ? heading : 'Write Your Own Adventure'}</h1>
        <p>
          {subheading
            ? subheading
            : 'You have the imagination, we have the skills. Our ghostwriters will create stories that reflect your vision and voice.'}
        </p>
        <div className={styles.ctaContainer} ref={buttonsRef}>
          <PrimaryButton
            inlineStyle={active === 2 ? { background: '#7689E1' } : {}}
          >
            Get your free sample
          </PrimaryButton>
          <SecondaryButton
            inlineStyle={
              active === 2 ? { color: '#7689E1', borderColor: '#7689E1' } : {}
            }
          >
            Get Expert help
          </SecondaryButton>
        </div>
        <div className={styles.countWrapper}>
          <div className={styles.countLabel}>
            Don’t believe us? We’ve got the numbers to prove it.{' '}
          </div>
          <div
            className={styles.countList}
            // style={darkColor && { color: darkColor }}
          >
            <div className={styles.countContainer}>
              <div
                className={styles.number}
                style={darkColor ? { color: darkColor } : {}}
              >
                {active === 1 ? '8972' : '4322'}
              </div>
              <div className={styles.caption}>Bestseller books</div>
            </div>
            <div className={styles.countContainer}>
              <div
                className={styles.number}
                style={darkColor ? { color: darkColor } : {}}
              >
                {active === 1 ? '150K' : '095K'}
              </div>
              <div className={styles.caption}>Copies sold</div>
            </div>

            <div className={styles.countContainer}>
              <div
                className={styles.number}
                style={darkColor ? { color: darkColor } : {}}
              >
                {active === 1 ? '0187' : '0206'}
              </div>
              <div className={styles.caption}>Authors</div>
            </div>


          </div>
        </div>
      </Container>
      <div className={styles.bookContainer}>
        <div className={styles.stableBookList} ref={imgOneRef}>
          <div className={styles.leftImagesGroup}>
            <img
              src="/images/b_tana_french_in_the_woods.png"
              alt=""
              className={styles.book2}
            />
            <img
              src="/images/b_start_with_why.png"
              alt=""
              className={styles.book3}
            />
          </div>

          <img
            src="/images/b_extreme.png"
            alt=""
            className={styles.stableBooks}
          />
          <img
            src="/images/b_amp_it_up.png"
            alt=""
            className={styles.stableBooks}
          />
          <div className={styles.rightImagesGroup}>
            <img
              src="/images/b_the_wim_hof.png"
              alt=""
              className={styles.book1}
            />
            <img
              src="/images/b_you_r_badass.png "
              alt=""
              className={styles.book1}
            />
          </div>
        </div>
      </div>
      <div className={styles.bookList}></div>
    </div>
  )
}
export default FictionalHero
