export const serviceTypes = [
    { value: 'Sop/Lor/Essay', label: 'Sop/Lor/Essay' },
    {
        value: 'Dissertation/Research Paper/Thesis',
        label: 'Dissertation/Research Paper/Thesis',
    },
    {
        value: 'SEO Blogs/Website Content/Whitepapers',
        label: 'SEO Blogs/Website Content/Whitepapers',
    },
    {
        value: 'Linkedin/Resume/Cover Letters',
        label: 'Linkedin/Resume/Cover Letters',
    },
    {
        value: 'Script Writing/Book Writing/Ghostwriting',
        label: 'Script Writing/Book Writing/Ghostwriting',
    },
    {
        value: 'SEO-Optimized Product Descriptions',
        label: 'SEO-Optimized Product Descriptions',
    },
    { value: 'Press Release', label: 'Press Release' },
    { value: 'Other', label: 'Other' },
]