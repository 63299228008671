import Header from '@/Components/Header'
import Footer from '@/Components/Footer'
import ServiceOffer from '@/Components/Service/Offer'
import ServiceInclude from '@/Components/Service/Inculde'
import ServiceWhatWeDo from '@/Components/Service/WhatWeDo'
import ServiceForm from '@/Components/Service/Form'
import Newsletter from '@/Components/Home/Newsletter'
import Faqs from '@/Components/Service/Faqs'
import FictionalHero from '@/Components/Service/FictionalHero'
import ClientsHome from '@/Components/Home/Clients'
import { PageDataNonFictional } from '@/Const/nonfictional'
import { PageDataFictional } from '@/Const/fictional'
import { Helmet } from 'react-helmet'
const ClientData = [
  {
    text: '“I came across them while searching for SOP writing services. I approached them, and they delivered the SOP in no time. Overall, it was a good experience!”',
    color: '#FFF4F4',
  },
  {
    text: '“Estorytellers are genuinely good at storytelling. We have an ongoing ghostwriting project, and it’s all been going smoothly until now. Keep it up!”',
    color: '#FFE6E6',
  },
  {
    text: '“Estorytellers have a well-trained team of writers, I believe. Thank you for my well-written resume. It helped me get the job I wanted. So, thanks!”',
    color: '#FFDADA',
  },
  {
    text: '“I would recommend Estorytellers because of its transparency and customer service. They explained everything in the first meeting. So far, so good!”',
    color: '#FFCECE',
  },
]
const ServiceNonFictionalPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Exclusive Non Fiction Ghostwriting Services | Estorytellers
        </title>
        <meta
          name="description"
          content="Explore our professional non fiction ghostwriting services to bring your book ideas to life. Experienced ghostwriters for your non fiction project."
        />
      </Helmet>
      <Header />
      <FictionalHero
        lightColor={'#FFEEEF'}
        darkColor={'#FF3E4F'}
        active={2}
        heading={'Turn your knowledge into a bestseller'}
        subheading={
          "You've got the ideas, we've got  skill.  Together, we'll create a book that informs, inspires, and sounds like you, even if you haven't written a word yourself."
        }
      />
      <ServiceOffer
        data={PageDataNonFictional.offerList}
        color={PageDataNonFictional.lightColor}
        title={PageDataNonFictional.offerListHeading}
      />
      <ServiceInclude
        data={PageDataNonFictional.includeList}
        color={PageDataNonFictional.lightColor}
        title={PageDataNonFictional.includeTitle}
      />
      {/*<ServiceWhatWeDo*/}
      {/*  darkColor={PageDataNonFictional.darkColor}*/}
      {/*  lightColor={PageDataNonFictional.lightColor}*/}
      {/*/>*/}
      <ClientsHome
        bgColor={'#fff'}
        lightColor={PageDataNonFictional.lightColor}
        Data={ClientData}
        cardColor={PageDataNonFictional.lightColor}
      />
      <ServiceForm
        darkColor={PageDataNonFictional.darkColor}
        lightColor={PageDataNonFictional.lightColor}
      />
      <Faqs
        darkColor={PageDataNonFictional.darkColor}
        lightColor={PageDataNonFictional.lightColor}
      />
      <Newsletter lightColor={PageDataNonFictional.lightColor} />

      <Footer />
    </>
  )
}
export default ServiceNonFictionalPage
