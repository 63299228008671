export const PageDataFictional = {
  lightColor: '#F4F6FF',
  darkColor: '#8095F5',
  offerList: [
    {
      imgSrc: '/images/fictional_offer_1.svg',
      title: 'Romance',
      description:
        'Fall in love with our ability to create stories of passion, romance and happy endings.',
    },
    {
      imgSrc: '/images/fictional_offer_2.svg',
      title: 'Drama/Suspense',
      description:
        'Share your stories of drama and suspense through our team of skilled writers',
    },
    {
      imgSrc: '/images/fictional_offer_3.svg',
      title: 'Science Fiction',
      description:
        "Explore galaxies, and bend reality. We'll ghostwrite your futuristic masterpiece. ",
    },
    {
      imgSrc: '/images/fictional_offer_4.svg',
      title: 'Supernatural/Horror',
      description:
        'Give your horror stories a chance to scare the socks off your readers.',
    },
    {
      imgSrc: '/images/fictional_offer_5.svg',
      title: 'Mystery/Thriller',
      description:
        'Our ghostwriters will keep your readers guessing to the very last word.',
    },
    {
      imgSrc: '/images/fictional_offer_6.svg',
      title: "Children's Literature",
      description: 'Inspire young minds through your enchanting tales.',
    },
  ],
  includeList: [
    {
      imgSrc: '/images/fictional_include_1.svg',
      title: 'Book Planning',
    },
    {
      imgSrc: '/images/fictional_include_2.svg',
      title: 'Ghostwriting',
    },
    {
      imgSrc: '/images/fictional_include_3.svg',
      title: 'Editing',
    },
    {
      imgSrc: '/images/fictional_include_4.svg',
      title: 'Proposals',
    },
    {
      imgSrc: '/images/fictional_include_5.svg',
      title: 'Publishing',
    },
    {
      imgSrc: '/images/fictional_include_6.svg',
      title: 'Targeting',
    },
    {
      imgSrc: '/images/fictional_include_7.svg',
      title: 'Testing',
    },
    {
      imgSrc: '/images/fictional_include_8.svg',
      title: 'Coaching',
    },
    {
      imgSrc: '/images/fictional_include_9.svg',
      title: 'Strategy',
    },
    {
      imgSrc: '/images/fictional_include_10.svg',
      title: 'Introduction',
    },
    {
      imgSrc: '/images/fictional_include_11.svg',
      title: 'Submission',
    },
    {
      imgSrc: '/images/fictional_include_12.svg',
      title: 'Editing',
    },
  ],
}

