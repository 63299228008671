import styles from './styles.module.scss'
import Container from '@/Components/Shared/Container'
import React, { useRef } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import { useGSAP } from "@gsap/react";
import {Link} from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

let mm = gsap.matchMedia();

const BookTypes = () => {
  const wrapperRef = useRef();
  const contRef = useRef();
  const textRef = useRef();
  const listRef = useRef();
  const leftItemRef = useRef();
  const midItemRef = useRef();
  const rightItemRef = useRef();

  useGSAP(() => {
    mm.add("(min-width: 800px)", () => {
    let t1 = gsap.timeline({
      scrollTrigger: {
        trigger: wrapperRef.current,
        markers: false,
        pin: true, // pin the trigger element while active
        start: "top +=0", // when the top of the trigger hits the top of the viewport
        end: "+=2500 100%", // end after scrolling 500px beyond the start
        scrub: true,
        pinSpacing: true, // Adjust pin spacing
      }
    });

    t1.fromTo(textRef.current, {
      y: -100,
      opacity: 0,
      scale: 1.2,
      ease: "power1.easeInOut"
    }, {
      y: 0,
      opacity: 1,
      scale: 1,
      ease: "power1.easeInOut"
    }).fromTo(listRef.current, {
      y: 800,
      ease: "power1.easeInOut"
    }, {
      y: 0,
      ease: "power1.easeInOut"
    }).fromTo(leftItemRef.current, {

      y: 500,
      x: -200,
      ease: "power1.easeInOut"
    }, {
      x: 0,
      y: 0,
      ease: "power1.easeInOut"
    }).fromTo(midItemRef.current, {

      y: 550,
      x: 300,
      ease: "power1.easeInOut"
    }, {

      x: 0,
      y: 0,
      ease: "power1.easeInOut"
    }, '<').fromTo(rightItemRef.current, {

      y: -500,
      x: 700,
      ease: "power1.easeInOut"
    }, {

      x: 0,
      y: 0,
      ease: "power1.easeInOut"
    }, '<').fromTo(contRef.current, {
      opacity: 1,
      scale: 1,
      y: 0,
      ease: "power1.easeInOut"
    }, {
      opacity: 0,
      scale: 0.8,
      y: -100,
      ease: "power1.easeInOut"
    })

  })
  })

  return (
    <section className={styles.wrapper} ref={wrapperRef} id={'services'}>
      <Container >
        <div ref={contRef}>
          <h2 ref={textRef}>We write the standing ovation.</h2>
          <div className={styles.list} ref={listRef}>
            <Link to={'/service/fictional'} className={`${styles.card} ${styles.one}`}>
              <h3>Fictional</h3>
              <img src="/images/hero_book_left_5.png" alt="" ref={leftItemRef} />
            </Link>
            <Link to={'/service/non-fictional'} className={`${styles.card} ${styles.two}`}>
              <h3>Non-Fictional</h3>
              <img src="/images/hero_book_right_5.png" alt="" ref={midItemRef} />
            </Link>
            <Link to={'/service/ebook'} className={`${styles.card} ${styles.three}`}>
              <h3>E-books</h3>
              <img src="/images/hero_book_right_1.png" alt="" ref={rightItemRef} />
            </Link>
          </div>
        </div>
      </Container>
    </section>
  )
}
export default BookTypes
