import styles from './styles.module.scss'
import { Link } from 'react-router-dom'

const PageButton = ({ active, color }) => {
  return (
    <div className={styles.pageButtonContainer}>
      <div className={styles.buttonLayout}>
        <Link
          to={'/service/fictional'}
          className={`${styles.list} ${active === 1 && styles.active}`}
        >
          Fictional
        </Link>

        <Link
          to={'/service/non-fictional'}
          className={`${styles.list} ${active === 2 && styles.active}`}
          style={active === 2 ? { backgroundColor: color, color: '#fff' } : {}}
        >
          Non-fictional
        </Link>

        <Link
          to={'/service/ebook'}
          className={`${styles.list} ${active === 3 && styles.active}`}
          style={active === 3 ? { backgroundColor: color, color: '#232323' } : {}}
        >
          E-books
        </Link>
      </div>
    </div>
  )
}
export default PageButton
