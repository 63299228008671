import React, { useRef, createRef } from 'react'
import styles from './styles.module.scss'
import Container from '@/Components/Shared/Container'
import PrimaryButton from '@/Components/Shared/Buttons/Primary'
import { gsap, ScrollTrigger } from 'gsap/all'
import { useGSAP } from '@gsap/react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import { useMediaQuery } from 'react-responsive'

gsap.registerPlugin(ScrollTrigger)

let mm = gsap.matchMedia()

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  variableWidth: false,
}
const ProcessHome = () => {
  const data = [
    {
      imgSrc: '/images/google_meet.png',
      title: 'Team meeting',
      subTitle:
        'We discuss your vision and our team takes the time to understand your vision and requirements.',
      darkColor: '#6D80D6',
      lightColor: '#E0E5FF',
    },
    {
      imgSrc: '/images/chess.png',
      title: 'Strategic planning',
      subTitle:
        ' Once your goals and vision are clear, we develop a compelling framework for your story.',
      darkColor: '#FF3E4F',
      lightColor: '#FFF5F4',
    },
    {
      imgSrc: '/images/book.svg',
      title: 'Free sample!',
      subTitle: 'Analyze our writing style and give us your valuable feedback.',
      darkColor: '#30C523',
      lightColor: '#F5FFF4',
    },
    {
      imgSrc: '/images/hands.svg',
      title: ' Expert team assembly',
      subTitle:
        'Dedicated professionals assemble the perfect writers for your project. ',
      darkColor: '#FFC107',
      lightColor: '#FFF2CC',
    },
    {
      imgSrc: '/images/paper.svg',
      title: 'Chapter-wise Delivery',
      subTitle: ' Receive your book in manageable, easy-to-review sections.',
      darkColor: '#A86DD6',
      lightColor: '#F3E0FF',
    },
  ]

  const elementsRef = useRef(data.map(() => createRef()))
  const wrapperRef = useRef()

  useGSAP(() => {
    mm.add('(min-width: 800px)', () => {
      let t1 = gsap.timeline({
        scrollTrigger: {
          trigger: wrapperRef.current,
          markers: false,
          pin: true, // pin the trigger element while active
          start: 'top +=0', // when the top of the trigger hits the top of the viewport
          end: '+=5500 0%', // end after scrolling 500px beyond the start
          scrub: true,
          pinSpacing: true, // Adjust pin spacing
        },
      })

      t1.fromTo(
        elementsRef.current[0].current,
        {
          // y: 0,
          scale: 1,
          y: 150,
          x: 380,
          opacity: 0,
          rotation: 4,
          ease: 'power1.easeInOut',
        },
        {
          // y: -15,
          scale: 1.5,
          y: 150,
          x: 380,
          opacity: 1,
          rotation: 4,
          ease: 'power1.easeInOut',
        },
      )
        .fromTo(
          elementsRef.current[1].current,
          {
            // y: 0,
            scale: 1,
            y: 150,
            x: 0,
            opacity: 0,
            ease: 'power1.easeInOut',
          },
          {
            // y: -15,
            scale: 1.5,
            y: 150,
            x: 0,
            opacity: 1,
            ease: 'power1.easeInOut',
          },
        )
        .fromTo(
          elementsRef.current[2].current,
          {
            // y: 0,
            scale: 1,
            y: 150,
            x: -380,
            opacity: 0,
            rotation: -4,
            ease: 'power1.easeInOut',
          },
          {
            // y: -15,
            scale: 1.5,
            y: 150,
            x: -380,
            opacity: 1,
            rotation: -4,
            ease: 'power1.easeInOut',
          },
        )
        .fromTo(
          elementsRef.current[3].current,
          {
            // y: 0,
            scale: 1,
            y: -90,
            x: 200,
            opacity: 0,
            rotation: 3,
            ease: 'power1.easeInOut',
          },
          {
            // y: -15,
            scale: 1.5,
            y: -90,
            x: 200,
            opacity: 1,
            rotation: 3,
            ease: 'power1.easeInOut',
          },
        )
        .fromTo(
          elementsRef.current[4].current,
          {
            // y: 0,
            scale: 1,
            y: -90,
            x: -200,
            opacity: 0,
            rotation: -3,
            ease: 'power1.easeInOut',
          },
          {
            // y: -15,
            scale: 1.5,
            y: -90,
            x: -200,
            opacity: 1,
            rotation: -3,
            ease: 'power1.easeInOut',
          },
        )
        .fromTo(
          elementsRef.current[0].current,
          {
            // y: 0,
            scale: 1.5,
            y: 150,
            x: 380,
            rotation: 4,
            ease: 'power1.easeInOut',
          },
          {
            // y: -15,
            scale: 1,
            y: 0,
            x: 0,
            rotation: 0,
            ease: 'power1.easeInOut',
          },
        )
        .fromTo(
          elementsRef.current[1].current,
          {
            // y: 0,
            scale: 1.5,
            y: 150,
            x: 0,
            ease: 'power1.easeInOut',
          },
          {
            // y: -15,
            scale: 1,
            y: 0,
            x: 0,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          elementsRef.current[2].current,
          {
            // y: 0,
            scale: 1.5,
            y: 150,
            x: -380,
            rotation: -4,
            ease: 'power1.easeInOut',
          },
          {
            scale: 1,
            y: 0,
            x: 0,
            rotation: 0,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          elementsRef.current[3].current,
          {
            // y: 0,
            scale: 1.5,
            y: -90,
            x: 200,
            rotation: 3,
            ease: 'power1.easeInOut',
          },
          {
            // y: -15,
            scale: 1,
            y: 0,
            x: 0,
            rotation: 0,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          elementsRef.current[4].current,
          {
            // y: 0,
            scale: 1.5,
            y: -90,
            x: -200,
            rotation: -3,
            ease: 'power1.easeInOut',
          },
          {
            // y: -15,
            scale: 1,
            y: 0,
            x: 0,
            rotation: 0,
            ease: 'power1.easeInOut',
          },
          '<',
        )
    })
  })
  const isMobile = useMediaQuery({
    query: '(max-width: 576px)',
  })
  if (isMobile) {
    return (
      <div className={styles.wrapper}>
        <h5>Our process curated for you</h5>
        <div className={styles.sliderContainer}>
          <Slider {...settings} className={'home-process-slider'}>
            {data.map((item, index) => {
              return (
                <div>
                  <div
                    className={styles.card}
                    style={{ background: item.lightColor }}
                    ref={elementsRef.current[index]}
                    key={index}
                  >
                    <img src={item.imgSrc} alt="" />
                    <div className={styles.title}>{item.title}</div>
                    <div className={styles.subtitle}>{item.subTitle}</div>
                    <Link to="/contact-us">
                      <PrimaryButton
                        addedStyles={styles.cta}
                        inlineStyle={{ background: item.darkColor }}
                      >
                        Connect with our team
                      </PrimaryButton>
                    </Link>
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    )
  }
  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      <h5>Our process curated for you</h5>
      <Container>
        <div className={styles.cardList}>
          {data.map((item, index) => {
            return (
              <div
                className={styles.card}
                style={{ background: item.lightColor }}
                ref={elementsRef.current[index]}
                key={index}
              >
                <img src={item.imgSrc} alt="" />
                <div className={styles.title}>{item.title}</div>
                <div className={styles.subtitle}>{item.subTitle}</div>
                <Link to="/contact-us">
                  <PrimaryButton
                    addedStyles={styles.cta}
                    inlineStyle={{ background: item.darkColor }}
                  >
                    Connect with our team
                  </PrimaryButton>
                </Link>
              </div>
            )
          })}
        </div>
      </Container>
    </div>
  )
}
export default ProcessHome
