import Header from '@/Components/Header'
import Footer from '@/Components/Footer'
import ServiceOffer from '@/Components/Service/Offer'
import { PageDataFictional } from '@/Const/fictional'
import ServiceInclude from '@/Components/Service/Inculde'
import ServiceWhatWeDo from '@/Components/Service/WhatWeDo'
import ServiceForm from '@/Components/Service/Form'
import Newsletter from '@/Components/Home/Newsletter'
import FictionalHero from '@/Components/Service/FictionalHero'
import ClientsHome from '@/Components/Home/Clients'
import Faqs from '@/Components/Service/Faqs'
import { PageDataNonFictional } from '@/Const/nonfictional'
import { Helmet } from 'react-helmet';
const ClientData = [
  {
    text: '“I came across them while searching for SOP writing services. I approached them, and they delivered the SOP in no time. Overall, it was a good experience!”',
    color: '#F4F6FF',
  },
  {
    text: '“Estorytellers are genuinely good at storytelling. We have an ongoing ghostwriting project, and it’s all been going smoothly until now. Keep it up!”',
    color: '#E6EAFF',
  },
  {
    text: '“Estorytellers have a well-trained team of writers, I believe. Thank you for my well-written resume. It helped me get the job I wanted. So, thanks!”',
    color: '#DAE0FF',
  },
  {
    text: '“I would recommend Estorytellers because of its transparency and customer service. They explained everything in the first meeting. So far, so good!”',
    color: '#CED6FF',
  },
]
const ServiceFictionalPage = () => {
  return (
    <>
      <Helmet>
        <title>Best Fiction Ghostwriting Services | Estorytellers</title>
        <meta
          name="description"
          content="Professional fiction ghostwriting services to bring your stories to life. Our experienced ghostwriters create compelling novels and more. Contact us today!"
        />
      </Helmet>
      <Header />
      <FictionalHero active={1} darkColor={PageDataFictional.darkColor} />
      <ServiceOffer
        data={PageDataFictional.offerList}
        color={PageDataFictional.lightColor}
      />
      <ServiceInclude
        data={PageDataFictional.includeList}
        color={PageDataFictional.lightColor}
      />
      {/*<ServiceWhatWeDo*/}
      {/*  darkColor={PageDataFictional.darkColor}*/}
      {/*  lightColor={PageDataFictional.lightColor}*/}
      {/*/>*/}
      <ClientsHome
        lightColor={PageDataNonFictional.lightColor}
        Data={ClientData}
        bgColor={'#fff'}
        cardColor={PageDataFictional.lightColor}
      />
      <ServiceForm
        darkColor={PageDataFictional.darkColor}
        lightColor={PageDataFictional.lightColor}
      />
      <Faqs
        darkColor={PageDataFictional.darkColor}
        lightColor={PageDataFictional.lightColor}
      />
      <Newsletter />

      <Footer />
    </>
  )
}
export default ServiceFictionalPage
