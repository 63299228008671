import React, { useState, useEffect } from 'react'
import Slider from 'react-slick'
import styles from './styles.module.scss'

const CustomSlider = ({ Data, cardColor }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % Data.length)
    }, 3000) // Change slide every 3 seconds

    return () => clearInterval(interval) // Clear the interval when the component unmounts
  }, [])
  const nextCard = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % Data.length)
  }

  const prevCard = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + Data.length) % Data.length)
  }

  return (
    <div className={styles.sliderContainer}>
      {Data.map((card, index) => {
        let style = {
          transform: `translateX(${30 * index}px) scale(${1 - 0.1 * index})`,
          zIndex: Data.length - index,
          background: card.color
        }

        if (index === activeIndex) {
          style = {
            ...style,
            transform: 'translateX(0px) scale(1)',
            zIndex: Data.length,
            background: cardColor ? cardColor : card.color,
          }
        }

        return (
          <div key={index} className={styles.item} style={style}>
            <img src="/images/quote.svg" alt="" className={styles.quoteIcon} />
            <div className={styles.content}>{card.text}</div>
          </div>
        )
      })}

      <div className={styles.navigation}>
        <button onClick={prevCard}>
          <img src="/images/arrow-left.svg" alt="" />
        </button>
        <div className={styles.dots}>
          {Data.map((_, index) => (
            <div
              className={`${styles.dot}  ${activeIndex === index ? styles.active : {}}`}
            ></div>
          ))}
        </div>
        <button onClick={nextCard}>
          <img src="/images/arrow-right.svg" alt="" />
        </button>
      </div>
    </div>
  )
}

export default CustomSlider
