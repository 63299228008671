import styles from './styles.module.scss'
import Container from '@/Components/Shared/Container'
import SecondaryButton from '@/Components/Shared/Buttons/Secondary'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
const Header = () => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const [openMenu, serOpenMenu] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY)
    }

    window.addEventListener('scroll', handleScroll)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <div
      className={`${styles.wrapper}  ${scrollPosition <= 20 ? styles.transparent : styles.solid}`}
    >
      <Container>
        <header className={styles.header}>
          <Link to={'/'}>
            <img src="/images/logo.png" alt="logo" className={styles.logo} />
          </Link>
          <ul className={`${styles.menuList} ${openMenu && styles.open}`}>
            <li className={styles.letsTalk}>
              <Link to={'/contact-us'}>
                <SecondaryButton addedStyles={styles.cta}>
                  Let’s Talk!
                </SecondaryButton>
              </Link>
            </li>
            <li>
              <a href="/">Home</a>
            </li>

            <li>
              <a href="/service/fictional">Service</a>
            </li>
            {/*<li>About us</li>*/}
            <li>
              <Link to={'/blogs/'} target={'_blank'}>
                Blog
              </Link>
            </li>
          </ul>
          <Link to={'/contact-us'} className={styles.contactDesktop}>
            <SecondaryButton>Let’s Talk!</SecondaryButton>
          </Link>
          <img
            src={openMenu ? '/images/close.svg' : '/images/menu.svg'}
            alt=""
            className={styles.menuIcon}
            onClick={() => serOpenMenu(!openMenu)}
          />
        </header>
      </Container>
    </div>
  )
}
export default Header
