import styles from './styles.module.scss'
import Container from '@/Components/Shared/Container'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import React from 'react'
const ServiceForm = ({ darkColor, lightColor }) => {
  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email format').required('Required'),
    phoneNumber: Yup.string()
      .matches(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
        'Invalid phone number',
      )
      .required('Required'),
    serviceType: Yup.string().required('Required'),
  })

  // Initial form values
  const initialValues = {
    name: '',
    email: '',
    phoneNumber: '',
    serviceType: '', // Assuming a static value for demonstration
  }

  // Submit handler
  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await fetch(
        'https://staging.estorytellers.com/send_message.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        },
      )
      if (response.ok) {
        // Handle success
        console.log('Form submitted successfully')
      } else {
        // Handle server errors
        console.error('Server error')
      }
    } catch (error) {
      // Handle network errors
      console.error('Network error:', error)
    }
    setSubmitting(false)
  }
  return (
    <div className={styles.wrapper} style={{ background: lightColor }}>
      <Container>
        <div className={styles.row}>
          <div className={styles.content}>
            <h5>It’s time to share your stories with the world.</h5>
            <p>
              Collaborate with our team of highly skilled ghostwriters and
              editors to bring your visions to life in the form of a compelling
              Fiction book that earns a spot on the shelves.
            </p>
            <ul>
              <li>
                <div className={styles.divider}></div>
              </li>
              <li>
                <img src="/images/service_form_1.svg" alt="" />
                You get subject matter experts
              </li>
              <li>
                <div className={styles.divider}></div>
              </li>
              <li>
                <img src="/images/service_form_2.svg" alt="" />
                You get customized samples
              </li>
              <li>
                <div className={styles.divider}></div>
              </li>
              <li>
                <img src="/images/service_form_3.svg" alt="" />
                You get unlimited revision*
              </li>
              <li>
                <div className={styles.divider}></div>
              </li>
            </ul>
          </div>
          <div className={styles.formWrapper}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className={styles.formWrapper}>
                    <div>
                      <div
                        className={styles.formGroup}
                        style={lightColor ? { borderColor: darkColor } : {}}
                      >
                        <Field
                          name="name"
                          type="text"
                          placeholder="Name"
                          style={lightColor ? { color: darkColor } : {}}
                        />
                      </div>
                      <ErrorMessage name="name" component="span" />
                    </div>
                    <div>
                      <div
                        className={styles.formGroup}
                        style={lightColor ? { borderColor: darkColor } : {}}
                      >
                        <Field
                          name="email"
                          type="email"
                          placeholder="Email id"
                          style={lightColor ? { color: darkColor } : {}}
                        />
                      </div>
                      <ErrorMessage name="email" component="span" />
                    </div>
                    <div>
                      <div
                        className={styles.formGroup}
                        style={lightColor ? { borderColor: darkColor } : {}}
                      >
                        <Field
                          name="phoneNumber"
                          type="text"
                          placeholder="Phone no."
                          style={
                            lightColor
                              ? { borderColor: darkColor, color: darkColor }
                              : {}
                          }
                        />
                      </div>
                      <ErrorMessage name="phoneNumber" component="span" />
                    </div>
                    <div>
                      <div
                        className={styles.formGroup}
                        style={lightColor ? { borderColor: darkColor } : {}}
                      >
                        <Field
                          name="serviceType"
                          type="text"
                          placeholder="Fictional Writing"
                          style={
                            lightColor
                              ? { borderColor: darkColor, color: darkColor }
                              : {}
                          }
                        />
                      </div>
                      <ErrorMessage name="serviceType" component="span" />
                    </div>
                    <button
                      type="submit"
                      className={styles.cta}
                      style={darkColor ? { backgroundColor: darkColor } : {}}
                      disabled={isSubmitting}
                    >
                      Get your free sample
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </div>
  )
}
export default ServiceForm
