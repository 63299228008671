import styles from './styles.module.scss'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <div className={styles.wrapper}>
      {/*  Address */}
      <div className={styles.area}>
        <div className={styles.address}>
          <div className={styles.logName}>Estorytellers</div>
          <p className={styles.addr}>
            91springboard, Lotus, Andheri East, Plot No. D-5 Road No. 20, Marol
            MIDC, Andheri East, Mumbai, Maharashtra. 400069
          </p>

          <div className={styles.services}>
            <div className={styles.title}>Quick links</div>
            <ul>
              <li>
                <Link to={'/'}>Home</Link>
              </li>
              <li>
                <Link to={'/blogs/'}>Blog</Link>
              </li>
              {/*<li>*/}
              {/*  /!*<Link to={'/'}>About Us</Link>*!/*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <Link to={'/study-abroad-counselling'}>*/}
              {/*    Study Abroad Counselling*/}
              {/*  </Link>*/}
              {/*</li>*/}
              <li>
                <Link to={'/service/fictional'}>Services</Link>
              </li>
              <li>
                <Link to={'/contact-us'}>Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/*    Services*/}
      <div className={styles.area}>
        <div className={styles.services}>
          <div className={styles.title}>Services</div>
          <ul>
            <li>
              <Link to={'/service/fictional'}>Fictional Writing</Link>
            </li>
            <li>
              <Link to={'/service/non-fictional'}>Non-Fictional Writing</Link>
            </li>
            <li>
              <Link to={'/service/ebook'}>E-book Writing</Link>
            </li>
          </ul>
        </div>
      </div>

      {/*    Quick Links*/}
      <div className={styles.area}>
        <div className={`${styles.services} ${styles.genre}`}>
          <div className={styles.title}>Genre</div>
          <ul>
            <li>
              <Link to={'/'}>Romance Writing</Link>
            </li>
            <li>
              <Link to={'/'}>Children’s Literature</Link>
            </li>
            <li>
              <Link to={'/'}>Health and Wellness </Link>
            </li>
            <li>
              <Link to={'/'}> Drama/Suspense writing</Link>
            </li>
            <li>
              <Link to={'/'}> Memoir/Autobiography</Link>
            </li>
            <li>
              <Link to={'/'}>Travel and Culture </Link>
            </li>
            <li>
              <Link to={'/'}>Science Fiction Writing </Link>
            </li>
            <li>
              <Link to={'/'}>Biography Writing </Link>
            </li>
            <li>
              <Link to={'/'}> Spirituality Writing</Link>
            </li>
            <li>
              <Link to={'/'}> Supernatural/Horror Writing</Link>
            </li>
            <li>
              <Link to={'/'}> Self help/Psychology</Link>
            </li>
            <li>
              <Link to={'/'}>Comedy Writing </Link>
            </li>
            <li>
              <Link to={'/'}>Mystery Thriller Writing </Link>
            </li>
            <li>
              <Link to={'/'}>Thought Leadership </Link>
            </li>
            <li>
              <Link to={'/'}> Finance/Economy</Link>
            </li>
          </ul>
        </div>
      </div>
      {/*reviews*/}
      <div className={styles.area}>
        <div className={styles.rating}>
          <div className={styles.star}>
            <img src="/images/star.svg" alt="" />
            <img src="/images/star.svg" alt="" />
            <img src="/images/star.svg" alt="" />
            <img src="/images/star.svg" alt="" />
            <img src="/images/star.svg" alt="" />
          </div>
          <div>
            <span>5.0</span>/1,230 reviews
          </div>
        </div>
      </div>
      {/*    Social */}
      <div className={styles.area}>
        <div className={styles.copyRight}>
          <p>©2024 Estorytellers</p>
        </div>
      </div>
      {/*    Copywrite*/}
      <div className={styles.area}>
        <div className={styles.copyRight}>
        Registered Under Tutor Scholastic Learners Pvt. Ltd.
        </div>
      </div>
    </div>
  )
}
export default Footer
