import { useState, useRef } from 'react'
import styles from './styles.module.scss'
import { gsap, ScrollTrigger } from 'gsap/all'
import { useGSAP } from '@gsap/react'
const MakeDifference = () => {
  const [activeTab, setActiveTab] = useState(0)
  const imageRef = useRef()
  const buttonRef = useRef()
  const buttonRefTwo = useRef()
  const buttonRefThree = useRef()

  useGSAP(() => {
    const onClick = () => {
      gsap.fromTo(
        imageRef.current,
        {
          scale: 0.5,
          y: 100,
          ease: 'power1.easeInOut',
        },
        {
          scale: 1,
          y: 0,
          ease: 'power1.easeInOut',
        },
      )
    }
    buttonRef.current.addEventListener('click', onClick)
    buttonRefTwo.current.addEventListener('click', onClick)
    buttonRefThree.current.addEventListener('click', onClick)

    return () => {
      buttonRef.current.removeEventListener('click', onClick)
      buttonRefTwo.current.removeEventListener('click', onClick)
      buttonRefThree.current.removeEventListener('click', onClick)
    }
  })

  const handleTabClick = (index) => {
    setActiveTab(index)
  }

  const renderImage = () => {
    switch (activeTab) {
      case 0:
        return '/images/home_difference.png'
      case 1:
        return '/images/home_publishing.png'
      case 2:
        return '/images/home_difference_marketing.png'
      default:
        return '/images/home_difference.png'
    }
  }
  const renderHeading = () => {
    switch (activeTab) {
      case 0:
        return 'Untold stories holding you back? Let\'s set them free.'
      case 1:
        return 'Let us share your story. We believe it matters.'
      case 2:
        return 'Turn your words into a legacy. We publish your stories'
      default:
        return 'Writing'
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>We make a difference</div>
      <div className={styles.subTitle}>{renderHeading()}</div>
      <div className={styles.tabWrapper}>
        <div className={styles.tabList}>
          <div
            className={`${styles.tabItem} ${activeTab === 0 ? styles.active : ''} pills`}
            onClick={() => handleTabClick(0)}
            ref={buttonRef}
          >
            Writing
          </div>
          <div
            className={`${styles.tabItem} ${activeTab === 1 ? styles.active : ''} pills`}
            onClick={() => handleTabClick(1)}
            ref={buttonRefTwo}
          >
            Publishing
          </div>
          <div
            className={`${styles.tabItem} ${activeTab === 2 ? styles.active : ''} pills`}
            onClick={() => handleTabClick(2)}
            ref={buttonRefThree}
          >
            Marketing
          </div>
        </div>
        <div className={styles.tabContent}>
          <img
            src={renderImage()}
            alt=""
            className={styles.contentImage}
            ref={imageRef}
          />
        </div>
      </div>
    </div>
  )
}
export default MakeDifference
