import styles from '@/Styles/content.module.scss'
import Container from '@/Components/Shared/Container'
import Header from '@/Components/Header'
import Footer from '@/Components/Footer'
import { Link } from 'react-router-dom'
import Newsletter from '@/Components/Home/Newsletter'
const StudyAbroadCounselling = () => {
  return (
    <>
      <Header />

      <div className={styles.wrapper}>
        <div className={styles.hero}>
          <Container>
            <div className={styles.breadcrumb}>
              <Link to="/">Home</Link>{' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M0.833008 11.4447L6.16634 6.11141L0.833008 0.778076"
                  stroke="#606060"
                  stroke-width="1.06667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span>Study Abroad Counselling</span>
            </div>
            <h1>Study Abroad Counselling</h1>
          </Container>
        </div>
        <Container>
          <div className={styles.content}>
            Want to get into your dream university?
            <br />
            <br />
            <p>Now you can!</p>
            <br />
            <p>
              From university selection to the application process to visa
              counselling to being a mentor and a friend you need, Estorytellers
              are here to help you with every study abroad need that can be
              there.
            </p>
            <br />
            <p>
              Living in a fast-growing globalised world, international education
              has built its worth. The last two decades have seen a rise when it
              comes to the number of students leaving their home countries to
              study abroad. Studying abroad is an ideal way to learn in an
              immersive environment in today’s time. Learning in a new context,
              thinking out of the box, coming out of your comfort zone and
              accepting new challenges in a new city can help you improve your
              self-development as well as build your career for better. This
              type of personal growth that you can get from studying and
              experience living in another country is exceptional. Studying
              abroad will help you expand your boundaries and make you come face
              to face with every situation in every aspect of living or
              studying. Thus, enabling you to build and nurture added skills of
              living in a new environment. Studying abroad will help you explore
              the diversity in culture, show you how to adapt, help expand your
              problem-solving skills, and enhance your networking. Simply put,
              studying abroad will give you an overall holistic learning
              experience which cannot be experienced in normal college education
              or professional realm.
            </p>
            <br />
            <p>
              Estorytellers is a study abroad consultant to help solve all your
              queries, work with your application to get into your dream
              university, optimize your profile and aim to build your career one
              step ahead towards a brighter future.
            </p>
            <br />
            <p>With Estorytellers you get;</p>
            <ul>
              <li>Personalised guidance</li>
              <li>Tailored counselling</li>
              <li>Systematic approach</li>
              <li>Adherence to timelines</li>
            </ul>
            <p>
              Don’t get lost just filling out forms without knowing where and
              how to apply to study abroad!
            </p>
            <br />
            <p>
              Estorytellers will help you to match you with the best fit to
              study abroad and make your dream come true.
            </p>
            <br />
            <p>
              According to a study survey of approximately 300 students who had
              studied abroad in the past five years, the top factors for
              studying abroad were:
            </p>
            <ul>
              <li>Experience another culture: 90%</li>
              <li>Personal growth: 82%</li>
              <li>Career prospects and marketability: 74%</li>
              <li>Learn a new language: 47%</li>
              <li>Recommendation from a friend: 33%</li>
              <li>Career requirement: 25%</li>
            </ul>
            <br />
            <p>
              If you have the inquisitiveness, the passion and the interest to
              successfully study abroad. With expert assistance, planning, and
              management you can study abroad and make the most learning out of
              the experience.
            </p>
            <br />
            <p>Studying abroad impacts students positively by:</p>
            <br />
            <ul>
              <li>Better understanding the cultural values and biases</li>
              <li>
                Studying abroad serves as a catalyst for increased maturity
              </li>
              <li>It increases self-confidence</li>
              <li>
                Studying abroad influences subsequent educational experiences
              </li>
              <li>
                It helps acquire skill sets that influence future career path
              </li>
              <li>It gives a global learning perspective</li>
              <li>Expands networking options</li>
              <li>Enhances personal growth and development</li>
              <li>
                Builds added interpersonal, management and critical thinking
                skills
              </li>
              <li>
                Helps gain long term relationships across diverse cultures
              </li>
            </ul>
            <br />
            <p>
              The procedure at Estorytellers is systematic and simple, covering
              stages such as:
            </p>
            <br />
            <p>The planning stage:</p>
            <br />
            <ul>
              <li>How do I decide on a country to study in?</li>
              <li>What kind of colleges should I apply to?</li>
              <li>
                What qualifications and skills are required to study abroad?
              </li>
              <li>What types of challenges should I be ready to face?</li>
            </ul>
            <br />
            <p>The application process:</p>
            <br />
            <ul>
              <li>
                What documentation do I need to study abroad? How do I produce
                it, to stand out from the rest of the applicants? SOP and LOR
                services
              </li>
              <li>What type of tests should I take to study abroad?</li>
            </ul>
            <br />
            <p>The cost and expenses:</p>
            <br />
            <ul>
              <li>Can studying abroad be within the budget?</li>
              <li>How much should I save or spend to study abroad?</li>
              <li>How should I manage my finances?</li>
            </ul>
            <br />
            <p>‘What next?’:</p>
            <br />
            <ul>
              <li>What should I do next after the application process?</li>
              <li>What if I get rejected or refused?</li>
              <li>
                How to cope with the diversity and make self-valuable at a new
                admitted environment?
              </li>
            </ul>
            <br />
            <p>
              So, having known the process that goes under pursuing the dream to
              study abroad, it is clear that a proficient expert can help pass
              these hurdles and challenges quickly and effectively. When you
              chose to study abroad, there are various aspects you need to take
              in to account which are extremely crucial for the future ahead.
              Like receiving the right advice and information about
              universities, their application process, the information on
              specific countries, their rules and regulations and also the right
              courses or career choices for the future ahead. An expert help
              from counsellors like us can help take the big and life-changing
              decision, while smoothen the procedure of application to study
              abroad. As, the entire of application process to study abroad
              takes a great deal of meticulousness and following the guidelines
              to avoid making any mistake or missing out on any crucial step.
            </p>
            <br />
            <p>
              Estorytellers is a specialist assisting in study abroad
              applications and is a hassle-free way. An expert helping hand will
              aid to get answers to process, regulations, lifestyle, finance or
              other aspects that need to be considered while opting for studying
              abroad. Estorytellers have immense knowledge and experience in
              crafting the crucial application documents like SOP and LOR which
              help get admission to any desired university. They are like
              make-or-break aspects which Estorytellers know well to handle
              perfectly. Estorytellers can really turn ambition of studying
              abroad into a reality. Expert guidance from Estorytellers clearly
              forms the stepping stone towards your career building with
              studying abroad skills.
            </p>
            <br />
            <p>
              You would want a mentor, an inspiration, whose input and
              assistance sparks motivation in you. A mentor can tell you how to
              do it better, more effectively and in a different way, which is
              quite a challenging task in today’s time – the skill to stand out
              from other applicants. Thus, finding a professional that puts you
              in high gear, works with you on your study abroad goals, and
              concentrates on your needs is what Estorytellers represent.
            </p>
            <br/>

            <p>
              As per a modern analysis and evaluation survey:
            </p>
            <ul>
              <li>
                87% of mentors and mentees feel empowered by their relationships and have developed greater self-assurance
              </li>
              <li>
                98% mentees agree that mentoring has made them put them ahead for new and thought-provoking situations
              </li>
              <li>
                93% mentees feel a good mentor helped to understand the entire process smoothly and make informed decisions for their career ahead
              </li>
              <li>
                91% mentees deem that mentoring has made them more capable to identify their strengths and weaknesses
              </li>
            </ul>
            <br/>
            <p>
              Estorytellers will put in their hard efforts and expertise to help optimize your career potential through counselling and mentoring. In addition, their wise guidance which has helped many in the past to identify values that develop prospective career paths shall be seen for your future too.
            </p>
            <br/>
            <p>
              So, what are you waiting for?
            </p>
            <br/>
            <p>
              For all-inclusive study abroad counselling contact Estorytellers at contact@estorytellers.com and turn your dream to study abroad into reality!
            </p>
          </div>
        </Container>
      </div>
      <Newsletter />
      <Footer />
    </>
  )
}
export default StudyAbroadCounselling
