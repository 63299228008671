import Header from '@/Components/Header'
import Footer from '@/Components/Footer'
import ServiceOffer from '@/Components/Service/Offer'
import ServiceInclude from '@/Components/Service/Inculde'
import ServiceWhatWeDo from '@/Components/Service/WhatWeDo'
import ServiceForm from '@/Components/Service/Form'
import Newsletter from '@/Components/Home/Newsletter'
import Faqs from '@/Components/Service/Faqs'
import FictionalHero from '@/Components/Service/FictionalHero'
import ClientsHome from '@/Components/Home/Clients'
import { PageDataEbook } from '@/Const/ebook'
import EbookHero from '@/Components/Service/EbookHero'
import { PageDataNonFictional } from '@/Const/nonfictional'
import { Helmet } from 'react-helmet'
import Benefits from '@/Components/Service/Benefits'
const ClientData = [
  {
    text: '“I came across them while searching for SOP writing services. I approached them, and they delivered the SOP in no time. Overall, it was a good experience!”',
    color: '#FFFBEE',
  },
  {
    text: '“Estorytellers are genuinely good at storytelling. We have an ongoing ghostwriting project, and it’s all been going smoothly until now. Keep it up!”',
    color: '#FFEBB0',
  },
  {
    text: '“Estorytellers have a well-trained team of writers, I believe. Thank you for my well-written resume. It helped me get the job I wanted. So, thanks!”',
    color: '#FCE290',
  },
  {
    text: '“I would recommend Estorytellers because of its transparency and customer service. They explained everything in the first meeting. So far, so good!”',
    color: '#FBDA74',
  },
]
const ServiceEbookPage = () => {
  return (
    <>
      <Helmet>
        <title>Expert Fiction Ghostwriting Services | Estorytellers</title>
        <meta
          name="description"
          content="Turn your story ideas into captivating novels with professional ebook ghostwriting services from Estorytellers. Get a free quote today!"
        />
      </Helmet>
      <Header />
      <EbookHero />
      <ServiceOffer
        data={PageDataEbook.offerList}
        color={PageDataEbook.lightColor}
        title={PageDataEbook.offerListHeading}
      />
      <ServiceInclude
        gridSize={4}
        data={PageDataEbook.includeList}
        color={PageDataEbook.lightColor}
        title={PageDataEbook.includeTitle}
      />
      <Benefits />
      {/*<ServiceWhatWeDo*/}
      {/*  darkColor={PageDataEbook.darkColor}*/}
      {/*  lightColor={PageDataEbook.lightColor}*/}
      {/*  title={PageDataEbook.articleTitle}*/}
      {/*/>*/}
      <ClientsHome
        bgColor={'#fff'}
        Data={ClientData}
        cardColor={PageDataEbook.lightColor}
      />
      <ServiceForm
        darkColor={PageDataEbook.darkColor}
        lightColor={PageDataEbook.lightColor}
      />
      <Faqs
        darkColor={PageDataEbook.darkColor}
        lightColor={PageDataEbook.lightColor}
      />
      <Newsletter lightColor={PageDataEbook.lightColor} />

      <Footer />
    </>
  )
}
export default ServiceEbookPage
