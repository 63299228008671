import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import styles from './styles.module.scss'
import Container from '@/Components/Shared/Container'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Link } from 'react-router-dom'
import { serviceTypes } from '@/Const/ContactUs'

const initialValues = {
  name: '',
  email: '',
  phoneNumber: '',
  serviceType: '',
  additionalDetails: '',
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email format').required('Required'),
  phoneNumber: Yup.string().required('Required'),
  serviceType: Yup.string().required('Required'),
  additionalDetails: Yup.string(),
})

const FormContactUs = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const onSubmit = async (values, { resetForm }) => {
    setIsSubmitting(true)
    // Construct the payload from the form values
    const payload = {
      name: values.name,
      email: values.email,
      phoneNumber: values.phoneNumber,
      serviceType: values.serviceType,
      additionalDetails: values.additionalDetails,
    }

    // Use the fetch API to send a POST request to the specified URL
    try {
      const response = await fetch(
        'https://staging.estorytellers.com/send_message.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // If the server expects other headers, add them here
          },
          body: JSON.stringify(payload), // Convert the payload into a JSON string
        },
      )

      if (response.ok) {
        setSubmitSuccess(true)
        setIsSubmitting(false)
        resetForm() // Clear the form

        // Optionally, hide the submit success message after a delay
        setTimeout(() => {
          setSubmitSuccess(false)
        }, 3000)
      } else {
        throw new Error('Network response was not ok.')
      }
    } catch (error) {
      setIsSubmitting(false)
      console.error('There was a problem with the fetch operation:', error)
      // Handle the error (e.g., displaying an error message to the user)
    }
  }

  const renderButton = () => {
    if (isSubmitting) {
      return (
        <button type="submit" disabled className={styles.submitting}>
          <div className={styles.loader}></div>
          Submitting your details
        </button>
      )
    }
    if (submitSuccess) {
      return (
        <button type="submit" disabled className={styles.submitted}>
          <img src="/images/check.svg" alt="" />
          Thank you for your response
        </button>
      )
    }
    return <button type="submit">Start your journey today!</button>
  }
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.formWrapper}>
            <div className={styles.internal}>
              <h2>Contact Us</h2>
              <p>
                Get in Touch with Us to Unlock Your Story's Potential with Our
                Ghostwriting Expertise
              </p>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ setFieldValue, values }) => (
                  <Form>
                    <div className={styles.row}>
                      <div>
                        <div className={styles.formGroup}>
                          <Field name="name" type="text" placeholder={'Name'} />
                        </div>
                        <ErrorMessage name="name" component="span" />
                      </div>
                      <div>
                        <div className={styles.formGroup}>
                          <Field
                            name="email"
                            type="email"
                            placeholder={'Email Id'}
                          />
                        </div>
                        <ErrorMessage name="email" component="span" />
                      </div>
                      <div>
                        <div className={styles.formGroup}>
                          <PhoneInput
                            international
                            defaultCountry="IN"
                            value={values.phoneNumber}
                            placeholder="Enter phone number"
                            onChange={(value) =>
                              setFieldValue('phoneNumber', value)
                            }
                          />
                        </div>
                        <ErrorMessage name="phoneNumber" component="span" />
                      </div>
                      <div>
                        <div className={styles.formGroup}>
                          <Field as="select" name="serviceType">
                            <option value="" disabled>
                              Service Type
                            </option>
                            {serviceTypes.map((type) => (
                              <option key={type.value} value={type.value}>
                                {type.label}
                              </option>
                            ))}
                          </Field>
                        </div>
                        <ErrorMessage name="serviceType" component="span" />
                      </div>
                    </div>
                    <div className={styles.addtionalformGroup}>
                      <Field
                        name="additionalDetails"
                        type="text"
                        placeholder={'Additional Details'}
                      />
                    </div>
                    <ErrorMessage name="additionalDetails" component="span" />

                    {renderButton()}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className={styles.details}>
            <img src="/images/contatc_us_right.png" alt="" />
          </div>
        </div>
      </div>
    </>
  )
}

export default FormContactUs
