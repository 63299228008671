import styles from './styles.module.scss'
import Container from '@/Components/Shared/Container'
import { useEffect, useState } from 'react'
import axios from 'axios'
import he from 'he'
import { Link } from 'react-router-dom'
const RecentBlogs = () => {
  const blogs = ['Blog 1', 'Blog 2', 'Blog 3']
  const [posts, setPosts] = useState([])

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const url = 'https://estorytellers.com/wp-json/wp/v2/posts?per_page=3'
        const response = await axios.get(url)
        console.log('response:', response.data)
        setPosts(response.data)
      } catch (error) {
        console.error('Error fetching posts:', error)
        setPosts([])
      }
    }

    fetchPosts()
  }, [])
  return (
    <div className={styles.wrapper}>
      <Container>
        <h5>Recent Blog Posts</h5>
        <div className={styles.blogList}>
          {posts.map((item, index) => {
            return (
              <Link to={item.link} key={index} className={styles.blogCard} target={'_blank'}>
                <div className={styles.cat}>{item?.x_categories}</div>
                <div className={styles.title}>
                  {he.decode(item.title?.rendered)}
                </div>
                <div
                  className={styles.subTitle}
                  dangerouslySetInnerHTML={{
                    __html: he.decode(item.excerpt?.rendered),
                  }}
                ></div>
                <div className={styles.bottomPanel}>
                  <div className={styles.date}>{item.x_date}</div>
                  <div className={styles.divider}></div>
                  <div className={styles.author}>{item.x_author}</div>
                </div>
              </Link>
            )
          })}
        </div>
      </Container>
    </div>
  )
}
export default RecentBlogs
