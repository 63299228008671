import styles from '@/Styles/content.module.scss'
import Container from '@/Components/Shared/Container'
import Header from '@/Components/Header'
import Footer from '@/Components/Footer'
import { Link } from 'react-router-dom'
const PrivacyPolicy = () => {
  return (
    <>
      <Header />

      <div className={styles.wrapper}>
        <div className={styles.hero}>
          <Container>
            <div className={styles.breadcrumb}>
              <Link to="/">Home</Link>{' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M0.833008 11.4447L6.16634 6.11141L0.833008 0.778076"
                  stroke="#606060"
                  stroke-width="1.06667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span>Privacy Policy</span>
            </div>
            <h1>Privacy Policy</h1>
          </Container>
        </div>
        <Container>
          <div className={styles.content}>
            <h3>ARTICLE 1- DEFINITIONS</h3>

            <ol>
              <li>
                Applicable Website: This Privacy Policy applies to
                Estorytellers, and henceforth estorytellers.com will be referred
                to as “Website.” “Website” will also cover any future
                development of subsidiary websites and mobile applications.
              </li>
              <li>
                Effective Date: The date that this Privacy Policy comes into
                effect will be known as “Effective Date.” So, the effective date
                for this Privacy Policy is (insert date).
              </li>
              <li>
                Parties: The data controller, Estorytellers, and the user, you,
                will hereafter be individually referred to as “Party” and
                unitedly referred to as “Parties.”
              </li>
              <li>
                Data Controller: The owner, publisher, and operator of the
                website are the Data Controller. Data Controller is the party
                responsible for the collection of data. The Data Controller or
                the Data Controller’s property will be referred to using
                first-person pronouns such as we, us, our and ours.
              </li>
              <li>
                User: Contingent on your agreement to the Privacy Policy and
                continued use of the website, the user will here forth be
                referred to like you, the user or any applicable second-person
                pronoun such as your and yours.
              </li>
              <li>
                Services: Any services that we provide and sell through the
                website will be known as “Services.”
              </li>
              <li>
                Personal Data: Personal data will be used to refer to any
                personal data and information that we acquire from your use of
                the website and our services.
              </li>
            </ol>

            <br/>
            <h3>ARTICLE 2 – GENERAL INFORMATION</h3>
            <p>
              This Privacy Policy details how the Personal Data that we receive
              is to be used. This Privacy Policy also covers the rights you
              possess concerning the Personal Data when you use this Website and
              our Services. This Privacy Policy does not diversify any details
              about you that we have received through sources other than the
              website. This Privacy Policy does not expand any of the linked
              websites or applications.
            </p>
            <p>
              We are pledged to the protection of your privacy concerning any
              information you choose to share with us. By continuing the usage
              of our website, you have acknowledged this Privacy Policy and
              agree to the same. You also consent to the use of any information
              disclosed to us through the website. If you disagree with this
              Privacy Policy, cease the use of this website immediately.
            </p>
            <br/>
            <h3>ARTICLE 3 – COMMUNICATION AND DATA PROTECTION OFFICER</h3>
            <p>
              The Party liable for processing your Personal Data is
              Estorytellers. The Data Controller and the operator of the website
              are Estorytellers. The Data Controller may be contacted here.
              (Insert hyperlink) The Data Protection Officer is (insert name)
              and may be contacted here. (Insert hyperlink)
            </p>
            <br/>
            <h3>ARTICLE 4 – LOCATION</h3>
            <p>
              Data processing and related activities take place in (insert
              location). Data may be transferred to companies outside
              (location), but it will be done such that it adheres to the EU’s
              General Data Protection Regulation or GDPR.
            </p>
            <br/>
            <h3>ARTICLE 5 – THE PERSONAL DATA WE RECEIVE FROM YOU</h3>
            <p>
              Depending on your use of our website, you will be subjected to
              different types of Personal Data and its various types of
              collections.
            </p>
            <br/>
            <ol>
              <li>
                Registered Users: As a user of the website, you may be asked to
                register to use the website or to purchase our Services.
              </li>
            </ol>
            <br/>

            <p>
              During the registration process, the following Personal Data will
              be collected, contingent on your voluntary disclosure of the same:
              Name and Email address.
            </p>
            <p>Personal Data may be asked for the following reasons.</p>
            <ol>
              <li>
                To interact with our representatives through phone, chat, email,
                and/or any other means
              </li>
              <li>To make purchases</li>
              <li>To receive email notifications regarding marketing</li>
              <li>To receive out general emails</li>
              <li>
                To comment on our content and/or other user-generated content on
                our website. The content may be in the form of blogs, articles,
                photographs, videos, participating in forums, bulletin boards,
                chat rooms, and other similar features.
              </li>
              <li>To access our free tools via your email</li>
            </ol>
            <br/>

            <p>
              By completing the registration process, you grant the collection
              of the Personal Data mentioned above and any data mentioned in the
              Privacy Policy, as well as storing, using and disclosing of the
              same per the Privacy Policy.
            </p>
            <ol>
              <li>
                Unregistered Users: If you tend to be a passive user of the
                website, i.e., you are not registered with the website; you are
                still subject to Passive Data Collection. Passive Data
                Collection implies the collection of data through cookies, IP
                address information, location information, and browser data like
                history and/or session information.
              </li>
              <li>
                All Users: The Passive Data collection applies to all users,
                i.e., unregistered users as well as registered users and
                visitors of the website.
              </li>
              <li>
                Sales and Billing Information: For the purchase of any service
                offered on the website, you will be asked for your credit
                information, billing address information, and additional
                specific information for the proper charge of your purchase. The
                payment and billing information can be stored up to 365 days for
                active customers and 90 days upon account cancellation. The
                information is stored to assist you in future purchases with us.
              </li>
              <li>
                Email Marketing: You may be requested to provide us with your
                name and email address to receive email marketing
                communications. This information can only be obtained through
                your voluntary disclosure.
              </li>
              <li>
                User Experience: We may request information from you for
                demographic information and your preferences on occasion. This
                information is to gather feedback regarding our website and the
                Services we sell. This information can only be acquired through
                your voluntary divulgence.
              </li>
              <li>
                Content Reciprocity: Our Website may allow you to reflect on our
                content or user-provided content as specified in article 5
                section a subsection v. We may collect information that
                includes, but is not limited to, user name and email address to
                allow us to respond to your comment if necessary and
                appropriate.
              </li>
              <li>
                Combined or Aggregated Information: We reserve the right to
                combine the Personal Data we collect to enable us to assist you
                and other consumers efficiently.
              </li>
            </ol>
            <br/>

            <h3>ARTICLE 6 – THE PERSONAL DATA WE AUTOMATICALLY RECEIVE</h3>
            <ol>
              <li>
                <strong>Cookies:</strong>
                <p>
                  We may collect information regarding your browsing preferences
                  and such through automatic tracking systems. We may also
                  collect information that you voluntarily disclose through our
                  website.
                </p>
                <p>
                  Cookies are used to make the browsing experience more
                  comfortable and fit to your personal preferences. Cookies are
                  short strings of text used to reserve information regarding a
                  user’s preference and the device they use to access the
                  internet to offer a personalized experience with the website
                  and store your previous preferences. A reduced set of data
                  sent to the user’s browser from a web server is essentially a
                  cookie.
                </p>
                <p>
                  Cookies do not record and store any Personal Data, and neither
                  do they have a risk of transmitting viruses. If you would like
                  to prevent the use of cookies, you may do so, but the website
                  may not work how it was intended to. If you do not have any
                  issues with our Cookie Policy, continue your use of our
                  website.
                </p>
              </li>
              <li>
                <strong>Technical Cookies</strong>
                <p>
                  Technical cookies or HTML cookies are used for your ease of
                  navigation and to facilitate your access and use of the
                  website. The use of technical cookies enables you to use the
                  website safely and efficiently. HTML cookies are necessary for
                  communication and to supply the service you requested.
                </p>
                <p>
                  You can manage, deactivate, or cancel the use of cookies
                  through your browser. However, please be conscious of it that
                  doing so may slow down the website and prevent access to some
                  parts of the website. These cookies may be used to collect
                  combined information on the users and how the website is being
                  used through a third-party analytics or statistics provider.
                  The above described is the essential operation of technical
                  cookies.
                </p>
                <p>
                  Temporary cookies are automatically deleted when you end the
                  browsing session, but permanent cookies remain active longer
                  than one particular session. Temporary cookies are used to
                  identify you so that you do not have to log in each time you
                  use the website.
                </p>
                <p>
                  Third-Party Cookies are in no way involved with our website
                  and are sent by a third party to your computer.
                </p>
              </li>
              <li>
                <strong>Support in Configuring your Browser:</strong>
                <p>
                  If you wish to manage the cookies, that can be done via the
                  settings page of the browser on your device. Please be aware
                  that deleting cookies can remove any preferences that may have
                  been set for the website.
                </p>
                <p>
                  For more information, visit the help page of the browser that
                  you are using.
                </p>
              </li>
              <li>
                <strong>Log Data:</strong>
                <p>
                  Log files that automatic store information collected during
                  various visits is used by this website, just as a majority of
                  websites make use of log files. The different types of log
                  data that can be stored are as follows.
                </p>
                <ul>
                  <li>Internet Protocol (IP) Address</li>
                  <li>
                    Type of browser and device used to view and visit the
                    website
                  </li>
                  <li>Name of Internet Service Provider (ISP)</li>
                  <li>Date and time of visit</li>
                  <li>Referral page of the user and the exit</li>
                  <li>The number of clicks</li>
                </ul>
                <p>
                  The information mentioned above is collected automatically in
                  aggregate form for the proper functioning of the Website and
                  security reasons. The data is processed according to the
                  requirements of the Data Controller.
                </p>
              </li>
            </ol>
            <br/>
            <h3>ARTICLE 7 – THIRD PARTIES</h3>
            <p>
              We reserve the right to Third Party Service Providers to assist
              with the website and to improve our Services. We may use the Third
              Party Service Providers for information storage, including but not
              limited to, cloud storage.
            </p>
            <p>
              We may provide some of the Personal Data you relay to us to Third
              Party Service Providers. This is done to help us track usage data
              which could mean referral websites, dates, and times of page
              requests, among others. This information is used to understand
              patterns of usage of the website and to improve the website
              further.
            </p>
            <p>
              Third-Party Service Providers may also be used to host the website
              and fulfill orders concerning the website.
            </p>
            <p>
              (Some of our Third-Party Service Providers may be located outside
              India. (If so, where is the Third Party Service Provider located?)
            </p>
            <p>
              The Personal Data you reveal to us will not be sold or transferred
              by any means to other third parties without your prior consent.
            </p>
            <p>
              However, we reserve the right to provide your Personal Data to a
              third party or multiple third parties for the protection of
              rights, property, and/or safety of us, our customers and/or third
              parties, or as required by law.
            </p>
            <p>
              Your Personal Data will not be shared with any third parties other
              than in the conditions stipulated in this Privacy Policy. If your
              Personal Data is to be shared with a third party other than the
              conditions specified, you will be notified beforehand and will be
              allowed to prevent the sharing of your Personal Data.
            </p>
            <br/>
            <h3>ARTICLE 8 – SOCIAL MEDIA PLUGINS</h3>
            <p>(Any Social Media Plug-ins? Only noticed link to YouTube)</p>
            <br/>
            <h3>ARTICLE 9 – HOW PERSONAL DATA IS RETAINED</h3>
            <p>
              Your Personal Data is stored in secure physical and digital
              systems, as and when appropriate, to protect it from unauthorized
              access, disclosure and/or destruction.
            </p>
            <p>
              It is to be noted that systems involving the transmission of data
              via the internet and its electronic storage are not completely
              secure. However, we take the security and storage of your Personal
              Data with the utmost seriousness and take all the necessary steps
              to do so.
            </p>
            <p>
              Only the assigned writing team, which consists of the writer(s),
              editor(s) and project manager will have access to your Personal
              Data. Purchase transaction details and payment methods are stored
              and processed by a third party. Reputable third parties are hosts
              for the website, which are all backed by SSL certificates.
            </p>
            <p>
              Your Personal Data is stored for the duration of your relationship
              with us. Personal Data will be deleted when you request a
              cancellation of your accounts or any specific information.
            </p>
            <p>
              In the matter of a Personal Data breach, all applicable measures
              will be taken to follow the laws in such an event. You will be
              informed of the violation in a reasonable time frame of no later
              than two weeks since the date of the breach.
            </p>
            <br/>
            <h3>ARTICLE 10 – PURPOSES OF PROCESSING PERSONAL DATA</h3>
            <p>
              The primary use of your Personal Data is to provide the services
              and/or information you require to deliver a better experience with
              the website.
            </p>
            <p>
              Information that doesn’t identify you, such as but not limited to,
              cookies are meant to provide an overall view of our customer base
              to help market research and the efforts of the marketing team.
            </p>
            <p>
              Your Personal Data that can be considered identifying are used the
              below-given purposes.
            </p>
            <ul>
              <li>To improve your personal user experience</li>
              <li>
                To communicate with you about your user account and relationship
                with us
              </li>
              <li>To market and advertise, via email</li>
              <li>To fulfill the purchases you requested</li>
              <li>To provide the customer service you require</li>
              <li>
                To keep you informed about the updates of the website and all
                regarding terms
              </li>
            </ul>
            <br/>
            <h3>ARTICLE 11 – DISCLOSURE OF PERSONAL DATA</h3>
            <p>
              Although it has been detailed about the disclosure of your
              Personal Data in this Privacy Policy, we reserve the right to
              disclose data in cases that we believe are reasonable. Such
              instances are listed below but not limited to those listed.
            </p>
            <ul>
              <li>
                To satisfy any laws and regulations, local, state, or Federal
              </li>
              <li>
                To respond to requests in the case of criminal, civil or
                administrative processes, subpoenas, court orders, writs from
                the law enforcement and/or other governmental and legal bodies
              </li>
              <li>
                To take legal action against a user who has violated the law or
                the terms of use of the Website
              </li>
              <li>As is obligatory for the proper operation of the Website</li>
              <li>
                To cooperate with any lawful investigations regarding our users.
              </li>
              <li>
                In case of any suspected fraudulent activities on the website or
                activities that violate our Terms and Conditions and other
                applicable guidelines.
              </li>
            </ul>
            <br/>

            <h3>ARTICLE 12 – WITHDRAWING TRANSMITTALS FROM US</h3>
            <p>
              We may send you marketing and informational communication about
              our website, like announcements and other information. If you
              don’t wish to receive such divulgence, you may contact us here
              (insert hyperlink). You can also unsubscribe from such emails by
              clicking on the link provided on the bottom of such
              communications.
            </p>
            <p>
              You can unsubscribe from the above-mentioned type of emails, but
              you will still receive information that is specific to you as a
              user and your account with us.
            </p>
            <p>
              By disclosing your Personal Data to the website in any form, you
              have consented to the creation of a commercial relationship with
              us and have consented to any emails from us or third party
              affiliates. Even such unsolicited emails cannot be legally defined
              as SPAM.
            </p>
            <br/>
            <h3>
              ARTICLE 13 – MODIFYING, DELETING AND RETRIEVING YOUR INFORMATION
            </h3>
            <p>
              If you are willing to modify, delete, or access any information we
              have about you, you can put in a request with us.
            </p>
            <br/>
            <h3>ARTICLE 14 – ACCEPTANCE OF RISK</h3>
            <p>
              By the continued use of our website in any manner, you have
              acknowledged, accepted and agreed that transmission of information
              and data via the internet is not completely secure, no matter the
              measures which are taken. You also acknowledge, accept, and agree
              that we cannot guarantee the security of any information you
              disclose with us and that any transmission is done at your own
              risk.
            </p>
            <br/>
            <h3>ARTICLE 15 – YOUR RIGHTS</h3>
            <p>
              Your many rights concerning your Personal Data are as specified
              below.
            </p>
            <ul>
              <li>
                The right to be enlightened about the processing of your
                Personal Data
              </li>
              <li>The right to access your Personal Data</li>
              <li>The right to update your Personal Data</li>
              <li>The right to correct your Personal Data</li>
              <li>
                The right to oppose and/or limit the Personal Data processing
              </li>
              <li>
                The right to request for a stop in the processing of Personal
                Data and the deletion of the same
              </li>
              <li>
                The right to block Personal Data processing concerning any
                applicable law
              </li>
            </ul>
            <p>
              You can exercise the aforementioned rights by contacting us here.
              (Insert hyperlink)
            </p>
            <br/>
            <h3>ARTICLE 16 – CONTACT INFORMATION</h3>
            <p>
              If you have any questions/queries about this Privacy Policy, the
              manner of collection of data or if you’d like to launch an
              objection regarding the Privacy Policy, you may contact us here.
              (Insert hyperlink)
            </p>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  )
}
export default PrivacyPolicy
