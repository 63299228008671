import styles from './styles.module.scss'
import Container from '@/Components/Shared/Container'
const Benefits = () => {
  return (
    <div className={styles.wrapper}>
      <Container>
        <h4>Benefits of choosing us</h4>
        <div className={styles.list}>
          <div className={styles.card}>
            <img src="/images/ebook_benefits_1.svg" alt=""/>
            <h5>Easy to get started</h5>
            <p>Get started on your e-book journey effortlessly with our expert guidance.</p>
          </div>
          <div className={styles.card}>
            <img src="/images/ebook_benefits_2.svg" alt=""/>
            <h5>Good Quality</h5>
            <p>We don’t compromise on quality. Our writers are experienced, talented, and passionate about what they do.</p>
          </div>
          <div className={styles.card}>
            <img src="/images/ebook_benefits_3.svg" alt=""/>
            <h5>Delivery</h5>
            <p>Timely completion, guaranteed peace of mind.</p>
          </div>
        </div>
      </Container>
    </div>
  )
}
export default Benefits
