import styles from './styles.module.scss'
const SecondaryButton = ({ children, addedStyles, inlineStyle }) => {
  return (
    <button
      className={`${styles.wrapper} ${addedStyles && addedStyles}`}
      style={inlineStyle}
    >
      {children}
    </button>
  )
}
export default SecondaryButton
