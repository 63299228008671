import styles from './styles.module.scss'
import Container from '../../Shared/Container'
import React, { useRef, useState } from 'react'

const data = [
  {
    question: 'Why choose Estorytellers?',
    answer:
      'Estorytellers is a globally recognized professional ghostwriting company. Their services are loved and cherished among customers. The best thing about Estorytellers is their affordable pricing comes with exceptional storytellers who know how to write an engaging story for you! \n',
  },
  {
    question: 'What does a ghostwriter do?',
    answer:
      'A ghostwriter is a professional writer who writes on behalf of another person who is named as the author. Typically, the ghostwriter is hired to write a variety of content, including books, articles, speeches, and other texts. The ghostwriter conducts research, drafts the content, and revises it based on the client\'s feedback. Despite their significant contribution, the ghostwriter does not receive public credit for their work. The person who hired the ghostwriter is listed as the author, while the ghostwriter remains anonymous. This allows the "author" to produce a high-quality written work without needing to spend the time or effort to write it themselves.',
  },
  {
    question: 'How much should a ghostwriter cost?',
    answer:
      'As per industry standards, ghostwriters cost around $0.02 to $0.1 per word. However, this depends on the quality of the writer, the project’s size, and the ghostwriting company.',
  },
  {
    question: 'Are Ghost Writers Illegal?',
    answer:
      "No, ghostwriting is not illegal. Ghostwriters are professional writers hired to write for someone else, often with the understanding that the work will be published under the client’s name. It’s a common practice in various industries, including literature, music, and online content creation. However, it’s essential to maintain transparency and ethical practices. Always ensure you’re adhering to the rules and guidelines of your specific field or industry when employing the services of a ghostwriter.",
  },
  {
    question: 'Can AI Write a Book?',
    answer:
      'Yes, Artificial Intelligence (AI) can indeed write a book. However, while AI can produce grammatically correct sentences and coherent paragraphs, it currently lacks the human touch needed for compelling storytelling, such as understanding complex human emotions and experiences. Therefore, while AI can assist in the writing process, a human author’s creativity and insight remain irreplaceable.',
  },
  {
    question: 'Are your services 100% confidential?',
    answer:
      'Yes, our services are 100% confidential. Once you are onboarded, we sign an NDA with you, which gives you all the rights to the content we create. We never share our involvement in your SOP, resumes, guest posts, business books, novels, autobiography, etc., with any external bodies. So, rest assured.',
  },
]

const Faqs = ({ darkColor }) => {
  const [activeIndex, setActiveIndex] = useState(null)

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index)
  }
  return (
    <div className={styles.wrapper}>
      <Container>
        <h5>FAQs</h5>
        <div className={styles.subTitle}>Don’t worry, we have all the answers</div>

        <div className={styles.faqsCont}>
          {data.map((item, index) => {
            return (
              <div
                className={`${styles.items} ${
                  activeIndex === index ? styles.active : ''
                }`}
                key={index}
                style={darkColor && { borderColor: darkColor }}
              >
                <div
                  className={styles.header}
                  onClick={() => handleClick(index)}
                >
                  <p>{item.question}</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_1_3281)">
                      <path
                        d="M10.7101 0.0032959C4.86828 0.0032959 0.116211 4.75536 0.116211 10.5972C0.116211 16.439 4.86828 21.1911 10.7101 21.1911C16.5519 21.1911 21.304 16.4382 21.304 10.5972C21.304 4.75619 16.5519 0.0032959 10.7101 0.0032959ZM10.7101 19.5499C5.77422 19.5499 1.7574 15.5339 1.7574 10.5972C1.7574 5.66048 5.77422 1.64448 10.7101 1.64448C15.646 1.64448 19.6628 5.66048 19.6628 10.5972C19.6628 15.5339 15.6468 19.5499 10.7101 19.5499Z"
                        fill={darkColor ? darkColor : '#000'}
                      />
                      <path
                        d="M14.8108 9.70198H11.5284V6.41961C11.5284 5.96664 11.1616 5.599 10.7078 5.599C10.254 5.599 9.88716 5.96664 9.88716 6.41961V9.70198H6.60479C6.15099 9.70198 5.78418 10.0696 5.78418 10.5226C5.78418 10.9756 6.15099 11.3432 6.60479 11.3432H9.88716V14.6256C9.88716 15.0786 10.254 15.4462 10.7078 15.4462C11.1616 15.4462 11.5284 15.0786 11.5284 14.6256V11.3432H14.8108C15.2646 11.3432 15.6314 10.9756 15.6314 10.5226C15.6314 10.0696 15.2646 9.70198 14.8108 9.70198Z"
                        fill={darkColor ? darkColor : '#000'}
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_3281">
                        <rect
                          width="21.1878"
                          height="21.1878"
                          fill="white"
                          transform="translate(0.114258 0.0032959)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className={styles.answer}>{item.answer}</div>
              </div>
            )
          })}
        </div>
      </Container>
    </div>
  )
}

export default Faqs
