import styles from './styles.module.scss'
import Container from '@/Components/Shared/Container'
const Newsletter = ({lightColor}) => {
  return (
    <div className={styles.wrapper} >
      <Container>
        <div className={styles.card} style={lightColor&&{background:lightColor}}>
          <div className={styles.left}>
            <div className={styles.title}>Subscribe to <br/> our newsletter</div>
            <div className={styles.inputContainer}>
              <input type="text" placeholder="Your email" />
              <img src="/images/arrow_right.svg" alt="" />
            </div>
          </div>
            <div className={styles.right}>
                <img src="/images/news_b_1.png" alt=""/>
                <img src="/images/news_b_2.png" alt=""/>
                <img src="/images/news_b_3.png" alt=""/>
                <img src="/images/news_b_1.png" alt=""/>
                <img src="/images/news_b_2.png" alt=""/>
                <img src="/images/news_b_3.png" alt=""/>
            </div>
        </div>
      </Container>
    </div>
  )
}
export default Newsletter
