import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import HomePage from '@/Pages/Home'
import Header from '@/Components/Header'
import ServiceFictionalPage from '@/Pages/Service/Fictional'
import ServiceNonFictionalPage from '@/Pages/Service/NonFictional'
import ServiceEbookPage from '@/Pages/Service/Ebook'
import PrivacyPolicy from '@/Pages/PrivacyPolicy'
import ServicePolicies from '@/Pages/ServicePolicies'
import StudyAbroadCounselling from '@/Pages/StudyAbroadCounselling'
import ContactUs from '@/Pages/ContactUs'

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomePage />}></Route>
        <Route
          exact
          path="/service/fictional"
          element={<ServiceFictionalPage />}
        ></Route>
        <Route
          exact
          path="/service/non-fictional"
          element={<ServiceNonFictionalPage />}
        ></Route>{' '}
        <Route
          exact
          path="/service/ebook"
          element={<ServiceEbookPage />}
        ></Route>{' '}
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route
          exact
          path="/service-policies"
          element={<ServicePolicies />}
        ></Route>{' '}
        <Route
          exact
          path="/study-abroad-counselling"
          element={<StudyAbroadCounselling />}
        ></Route>{' '}
        <Route exact path="/contact-us" element={<ContactUs />}></Route>
      </Routes>
    </Router>
  )
}
export default App
