import styles from './styles.module.scss'
import PageButton from '@/Components/Service/PageButton'
import Container from '@/Components/Shared/Container'
import PrimaryButton from '@/Components/Shared/Buttons/Primary'
import Secondary from '@/Components/Shared/Buttons/Secondary'
import SecondaryButton from '@/Components/Shared/Buttons/Secondary'
const EbookHero = () => {
  return (
    <div className={styles.wrapper}>
      <Container>
        <PageButton active={3} color="#FFD700" />
        <div className={styles.row}>
          <div className={styles.content}>
            <h1>Your E-book Dream Team</h1>
            <p>
              Turn your passion into a profession with our team of experienced
              writers that will write your e-book and all the credit os yours to
              keep!
            </p>
            <div className={styles.ctaContainer}>
              <PrimaryButton inlineStyle={{ background: '#DAA505' }}>
                Get your free sample
              </PrimaryButton>
              <SecondaryButton
                inlineStyle={{ borderColor: '#DAA505', color: '#DAA505' }}
              >
                Get Expert help
              </SecondaryButton>
            </div>
            <div className={styles.countList}>
              <div className={styles.countContainer}>
                <div className={styles.number}>3867</div>
                <div className={styles.caption}>Bestseller books</div>
              </div>
              <div className={styles.countContainer}>
                <div className={styles.number}>300K</div>
                <div className={styles.caption}>Copies sold</div>
              </div>

              <div className={styles.countContainer}>
                <div className={styles.number}>098</div>
                <div className={styles.caption}>Authors </div>
              </div>


            </div>
          </div>
          <div className={styles.rightImage}>
            <img
              src="/images/ebook_hero.png"
              alt=""
            />
          </div>
        </div>
      </Container>
    </div>
  )
}
export default EbookHero
